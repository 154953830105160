import type { CountryCode} from 'rma-shared/lib/constants';
import { isEuropeanUnion } from 'rma-shared/lib/constants'

export type DeviceForwardType = 'forwarded-product' | 'forwarded-company'

export function isSkuForwardedToUI(coreSku: string, country?: CountryCode | null): DeviceForwardType | null {
  if (!country || (country !== 'US' && country !== 'CA' && country !== 'GB' && !isEuropeanUnion(country))) {
    return null
  }

  const sku = coreSku.toLocaleLowerCase()
  const isForwardedProduct =
    sku.indexOf('udm-pro') === 0 ||
    sku.indexOf('ua-pro') === 0 ||
    sku.indexOf('ua-sk') === 0 ||
    sku.indexOf('u6-lr') === 0 ||
    sku.indexOf('uvc-ai-bullet') === 0 ||
    sku.indexOf('uc-ev-station-ea') === 0 ||
    sku.indexOf('uc-ev-station-pro') === 0 ||
    sku.indexOf('uck-g2-plus') === 0 ||
    sku.indexOf('ucg-ultra') === 0 ||
    sku.indexOf('ua-g2-pro-black') === 0

  return isForwardedProduct ? 'forwarded-product' : null
}
