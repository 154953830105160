import { UbiquitiLogo } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'
import { Div } from '../Div'
import { Row } from '../SprintShared'

export const Dispute = ({ title, message }: { title: JSX.Element; message: JSX.Element }) => {
  return (
    <Div className="flex flex-column p-20 mb-16 s-12 bg-white border-radius-6">
      <Row>
        <StyledLogoInnerContainer>
          <UbiquitiLogo variant="bgRoundedSquare" />
        </StyledLogoInnerContainer>
        <Text size="body" weight="bold" color="primary">
          {title}
        </Text>
      </Row>

      <div>{message}</div>
    </Div>
  )
}

const StyledLogoInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`
