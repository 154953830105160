import { isValidMac, parseMacOrSerialInput, validateMacSerial } from '@shared/lib'
import { partialIncludes } from '@shared/lib/utils/partial-includes'
import type { Device, KitReturn, KitReturnDevice, UaG2KitReturn } from '@shared/types/devices'
import { KitReturnBaseSku } from '@shared/types/devices'
import type { KitEntry } from 'rma-shared/lib/devices/kit-devices'
import { createKitEntry, validateKitDevices, ValidationErrorMessage } from 'rma-shared/lib/devices/kit-devices'
import { useSubmitRmaStore } from './submit-rma-store'

export const KitReturnService = {
  checkForKitReturn(device: Device | null): KitReturn | null {
    if (!device || !device.mac || device.hasParent) {
      return null
    }

    const originalSku = device?.coreItemCode || ''
    const sku = originalSku.toLocaleLowerCase()

    if (sku.indexOf('afi-hd') === 0) {
      return {
        type: 'afi',
        option: 'whole-kit',
        devices: [],
      }
    }

    if (partialIncludes(KitReturnBaseSku, sku)) {
      const isPro = sku.indexOf('ua-g2-sk-pro') === 0
      const isStandard = !isPro && sku.indexOf('ua-g2-sk') === 0

      let option: UaG2KitReturn['option'] | null = null
      let devices: UaG2KitReturn['devices'] = []

      if (isPro) {
        option = 'ua-g2-sk-pro'
        devices = KitReturnService.createKitDevices(2)
      } else if (isStandard) {
        option = 'ua-g2-sk'
        devices = KitReturnService.createKitDevices(1)
      }

      if (!option && device.isUnifiCare) {
        option = 'not-part-of-kit'
      }

      return {
        type: 'ua-g2',
        option,
        returnSpecifics: 'whole-kit',
        devices,
      }
    }

    return null
  },

  createKitDevices(num: number) {
    const devices: KitReturnDevice[] = new Array(num) as KitReturnDevice[]

    for (let n = 0; n < num; n += 1) {
      devices[n] = {
        deviceId: null,
        error: '',
        index: n,
        loading: false,
        mac: '',
        sku: '',
      }
    }

    return devices
  },

  updateKitDevice(index: number, updatedData: Partial<KitReturnDevice>) {
    const kitDevice = getKitDevice(index)
    if (!kitDevice) {
      return
    }

    useSubmitRmaStore.getState().updateKitReturnDevice({
      ...kitDevice,
      ...updatedData,
    })
  },

  revalidateAllMacs(resultOfIndex: number) {
    const kitDevices = getKitDevices()

    let resultOfIndexErrored = false

    for (let n = 0; n < kitDevices.length; n += 1) {
      const kitDevice = kitDevices[n]
      if (!kitDevice.mac || kitDevice.loading || (kitDevice.mac && kitDevice.deviceId)) {
        continue
      }

      const error = validateKitMac(kitDevice.mac)
      if (error) {
        KitReturnService.updateKitDevice(kitDevice.index, { error })

        if (resultOfIndex === kitDevice.index) {
          resultOfIndexErrored = true
        }
      } else if (kitDevice.error) {
        KitReturnService.updateKitDevice(kitDevice.index, { error: '' })
      }
    }

    return resultOfIndexErrored
  },

  revalidateAllSkus() {
    const kitDevices = getKitDevices()

    for (const entry of kitDevices) {
      if (!entry.mac) {
        return true
      }
    }

    const { device, deviceStateCurr } = useSubmitRmaStore.getState()

    const { kitReturn } = deviceStateCurr
    if (!kitReturn || !device) {
      return false
    }

    for (const kitDevice of kitDevices) {
      if (kitDevice.error) {
        const error = validateKitMac(kitDevice.mac)
        if (error) {
          continue
        }

        KitReturnService.updateKitDevice(kitDevice.index, { error: undefined })
      }
    }

    const kitEntries: KitEntry[] = kitDevices
      .filter((entry) => !!entry.deviceId)
      .map((entry) => createKitEntry(entry.sku, entry.mac))
    kitEntries.push(createKitEntry(device.name, device.name))

    const error = validateKitDevices(kitEntries, kitReturn)
    if (error) {
      const kitDevice = error.kitDeviceIndex === -1 ? kitDevices[0] : kitDevices[error.kitDeviceIndex]

      KitReturnService.updateKitDevice(kitDevice.index, { error: ValidationErrorMessage })
    }

    return null
  },

  canStartFlow(): boolean {
    const { device, deviceStateCurr } = useSubmitRmaStore.getState()
    const { kitReturn } = deviceStateCurr

    if (!kitReturn) {
      return true
    }

    if (!device) {
      return false
    }

    if (kitReturn.type === 'ua-g2') {
      const macsValid = kitReturn.devices.filter((entry) => entry && entry.mac && !entry.error && !entry.loading).length

      if (kitReturn.option) {
        if (
          kitReturn.option === 'not-part-of-kit' ||
          kitReturn.option === 'sp-g2-pro-mount-kit' ||
          device.isUnifiCare
        ) {
          return true
        }

        return macsValid >= kitReturn.devices.length
      }

      return device.isUnifiCare
    }

    return true
  },
}

const validateKitMac = (mac: string): string | null => {
  const { device } = useSubmitRmaStore.getState()

  const parsedMac = parseMacOrSerialInput(mac)
  const parsedDeviceMac = parseMacOrSerialInput(device?.mac || '')

  if (parsedMac === parsedDeviceMac) {
    return 'This MAC ID is the same as the product above. Please enter the MAC ID(s) of the other kit item(s).'
  }

  if (!validateMacSerial(mac)) {
    return 'Invalid MAC ID'
  }

  if (!isValidMac(mac)) {
    return "It seems that you have entered a Serial Number instead of a MAC ID. Make sure you're entering the number highlighted to the right and try again."
  }

  if (!isMacUnique(mac)) {
    return 'You can not enter the same MAC ID twice'
  }

  return null
}

const getKitDevices = () => {
  const { deviceStateCurr } = useSubmitRmaStore.getState()

  if (!deviceStateCurr.kitReturn || deviceStateCurr.kitReturn.type !== 'ua-g2') {
    return []
  }

  return deviceStateCurr.kitReturn.devices
}

const getKitDevice = (index: number) => {
  const { deviceStateCurr } = useSubmitRmaStore.getState()

  if (!deviceStateCurr.kitReturn || deviceStateCurr.kitReturn.type !== 'ua-g2') {
    return null
  }

  return deviceStateCurr.kitReturn.devices[index] || null
}

const isMacUnique = (mac: string) => {
  const kitDevices = getKitDevices()
  const parsedMac = parseMacOrSerialInput(mac)

  let entries = 0

  for (const kitDevice of kitDevices) {
    if (parseMacOrSerialInput(kitDevice.mac) === parsedMac) {
      entries += 1

      if (entries > 1) {
        return false
      }
    }
  }

  return true
}
