import type { AfiKitReturn } from '@shared/types/devices'
import { Radio, Text } from '@ubnt/ui-components'
import type { ChangeEvent } from 'react'
import { useSubmitRmaStore } from '../../submit-rma-store'

export const AfiReturnConfirmation = ({ kitReturn }: { kitReturn: AfiKitReturn }) => {
  const { device, setKitReturn } = useSubmitRmaStore()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value !== 'on' || !device) {
      return
    }

    const afiReturnOptionNew = event.currentTarget.id as AfiKitReturn['option']

    setKitReturn({
      type: 'afi',
      option: afiReturnOptionNew,
      devices: [],
    })
  }

  return (
    <div className="s-8 mt-18">
      <Text size="body" weight="bold" className="my-8">
        Do you want to return hole kit or just AFi-R?
      </Text>

      <Radio id="whole-kit" variant="primary" checked={kitReturn.option === 'whole-kit'} onChange={handleChange}>
        Whole Kit
      </Radio>

      <Radio
        id="meshpoint-only"
        variant="primary"
        checked={kitReturn.option === 'meshpoint-only'}
        onChange={handleChange}
      >
        Meshpoint Only
      </Radio>
    </div>
  )
}
