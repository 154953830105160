import type { KitReturn } from '../../types/devices'

export interface KitEntry {
  sku: string
  mac: string
}

interface SkuMappings {
  unique: string[]
  other: string[]
}

export const ValidationErrorMessage = 'Invalid MAC ID. Please try again.'

function getValidKitSkuMapping(kitType: string): Record<string, SkuMappings> {
  if (kitType === 'ua-g2') {
    return {
      'ua-g2-sk': {
        unique: ['ua-g2', 'ua-hub-door'],
        other: ['ua-g2-sk'],
      },
      'ua-g2-sk-pro': {
        unique: ['ua-g2', 'ua-g2-pro', 'ua-hub-door'],
        other: ['ua-g2-sk-pro'],
      },
    }
  }

  return {}
}

function getMapping(kitType: string, kitOption: string) {
  const mappingType = getValidKitSkuMapping(kitType)
  if (!mappingType && kitType === 'ua-g2') {
    return null
  }

  return mappingType[kitOption] || null
}

export function validateKitDevice(kitEntry: KitEntry, kitType: string, kitOption: string) {
  const mapping = getMapping(kitType, kitOption)
  if (!mapping) {
    return true
  }

  return mapping && (mapping.unique.includes(kitEntry.sku) || mapping.other.includes(kitEntry.sku))
}

export function validateKitDevices(kitDevices: KitEntry[], kitReturn: KitReturn): { kitDeviceIndex: number } | null {
  if (!kitDevices.length) {
    return null
  }
  if (!kitReturn.option) {
    return { kitDeviceIndex: -1 }
  }

  const mapping = getMapping(kitReturn.type, kitReturn.option)
  if (!mapping) {
    if (kitReturn.type === 'ua-g2') {
      return null
    }

    return { kitDeviceIndex: -1 }
  }

  for (let n = 0; n < kitDevices.length; n += 1) {
    const kitEntry = kitDevices[n]

    if (!validateKitDevice(kitEntry, kitReturn.type, kitReturn.option)) {
      return { kitDeviceIndex: n }
    }
  }

  for (const unique of mapping.unique) {
    let entries = 0
    let firstCollision = 0

    // TODO: Simplify collision check
    for (let n = 0; n < kitDevices.length; n += 1) {
      const kitEntry = kitDevices[n]

      if (kitEntry.sku === unique) {
        if (entries === 0) {
          firstCollision = n
        }
        entries += 1

        if (entries > 1) {
          const isLastIndex = n === kitDevices.length - 1
          return { kitDeviceIndex: isLastIndex ? firstCollision : n }
        }
      }
    }
  }

  return null
}

export function createKitEntry(sku: string, mac: string | null): KitEntry {
  return { sku: sku.toLocaleLowerCase(), mac: mac || '' }
}
