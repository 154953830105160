import { useSubmitRmaStore } from '../../submit-rma-store'
import { AfiReturnConfirmation } from './afi-return-confirmation'
import { UaG2ReturnConfirmation } from './ua-g2-sk-return-confirmation'

export function ReturnConfirmation() {
  const { deviceStateCurr } = useSubmitRmaStore()

  if (!deviceStateCurr.kitReturn) {
    return null
  }

  switch (deviceStateCurr.kitReturn.type) {
    case 'afi':
      return <AfiReturnConfirmation kitReturn={deviceStateCurr.kitReturn} />

    case 'ua-g2':
      return <UaG2ReturnConfirmation kitReturn={deviceStateCurr.kitReturn} />

    default:
      return null
  }
}
