import { Grid, Link, Text } from '@shared-ui/components'
import { ProductsDropdown } from '@shared-ui/components/products-dropdown'
import { useRequest } from '@shared-ui/hooks'
import type { NetsuiteProductId } from '@shared/types/brands'
import { Modal } from '@ubnt/ui-components'
import { useState } from 'react'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'

const LINK_NETWORK_APPLICATION = 'https://unifi.ui.com'
const LINK_PRODUCTS = 'https://www.ui.com/products/#default'

export const ChooseHardware = () => {
  const [showProducts, setShowProducts] = useState(false)

  if (showProducts) {
    return <ModalProductsWithoutSerial handleBack={() => setShowProducts(false)} />
  }

  return (
    <Modal
      data-testid="unable-to-find-mac-id-modal-title"
      isOpen
      size="small"
      onRequestClose={SubmitRmaService.reset}
      actions={[
        {
          text: <span data-testid="unable-to-find-mac-id-modal-close-btn">Close</span>,
          variant: 'tertiary',
          onClick: SubmitRmaService.reset,
        },
      ]}
      title={<span data-testid="unable-to-find-mac-id-modal-title">How do I find the MAC ID or serial number?</span>}
    >
      <span data-testid="unable-to-find-mac-id-modal-description">
        Excluding select accessories*, all UniFi devices have a MAC ID or serial number that can be found on:
        <br />
        <br />
        <span data-testid="unable-to-find-mac-id-modal-note">The device&apos;s body</span> (<b>Note:</b> You may have to
        dismount it or remove protective port inserts to find it.)
        <br />
        <br />
        The <b>Overview</b> tab of the device’s properties panel, accessed by selecting it on the <b>UniFi Devices</b>{' '}
        page of your <Link href={LINK_NETWORK_APPLICATION}>Network application</Link>.
        <br />
        <br />
        <i>*Some accessories have their MAC ID/serial number printed on their packaging.</i>
        <br />
        <br />
        If you&apos;re trying to locate an AmpliFi device&apos;s MAC ID,{' '}
        <Link href="https://help.amplifi.com/hc/en-us/articles/115007243367-AmpliFi-Returns-Replacements-and-Warranty">
          click here
        </Link>
        .
        <br />
        <br />
        <strong>
          If your MAC ID/ Serial Number is still unknown or unrecognized,{' '}
          <Link
            data-testid="unable-to-find-mac-id-modal-click-here"
            href="#no-serial"
            onClick={(event) => {
              event.preventDefault()
              setShowProducts(true)
            }}
          >
            click here
          </Link>
          .
        </strong>
      </span>
    </Modal>
  )
}

const ModalProductsWithoutSerial = ({ handleBack }: { handleBack: () => void }) => {
  const [productName, setProductName] = useState<string | undefined>()
  const [productId, setProductId] = useState<NetsuiteProductId>('')
  const [productsError, setProductsError] = useState<string | undefined>(undefined)
  const [productsLoading, setProductsLoading] = useState(true)

  const [createUserDevice, { loading, error }] = useRequest('createDevice')

  const haveLoading = productsLoading || loading
  const haveError = !!error || productsError
  const nextDisabled = haveLoading || !productId

  const handleNext = async () => {
    const deviceResponse = await createUserDevice({
      mac: '',
      productId,
      productName: productName || '',
    })

    if (deviceResponse?.device) {
      if (!SubmitRmaService.processDevice(deviceResponse.device, deviceResponse.supportUICare)) {
        useSubmitRmaStore.setState({
          searchType: 'custom',
        })
        handleBack()
      } else {
        SubmitRmaService.startFlow()
      }
    }
  }

  return (
    <Modal
      isOpen
      onRequestClose={SubmitRmaService.reset}
      size="small"
      title="Still unable to find the MAC ID or serial number?"
      actions={[
        {
          text: 'Back',
          variant: 'tertiary',
          disabled: productsLoading,
          onClick: handleBack,
        },
        {
          text: 'Next',
          variant: 'primary',
          loader: loading ? 'dots' : undefined,
          disabled: nextDisabled,
          onClick: handleNext,
        },
      ]}
    >
      <Text size="body" className="mb-24">
        No worries, we can still process your RMA submission! Please select your product&apos;s SKU below and provide
        proof of purchase on the following screen.
        <br />
        <br />
        If you don’t know your product’s SKU, <Link href={LINK_PRODUCTS}>click here</Link>.
        <br />
        <br />
        Please note that since we can&apos;t refer to a specific MAC ID or serial number, it may take longer to review
        and approve your submission.
      </Text>

      <ProductsDropdown
        label="Select product"
        variant="secondary"
        onChange={(option) => {
          setProductName(option.label)
          setProductId(option.value as NetsuiteProductId)
        }}
        onLoading={(isLoading) => {
          setProductsLoading(isLoading)
          if (isLoading) {
            setProductId('')
          }
        }}
        onError={setProductsError}
      />

      {!haveLoading && haveError && (
        <Grid $container direction="column" gap="m">
          <Grid $item>
            <Grid $container direction="column" gap="xs">
              <Text $center color="danger">
                {error}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Modal>
  )
}
