import { Button, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import { TICKET_STATUS_LABELS } from 'rma-shared/tickets'
import { EscalatedType } from 'rma-shared/types/tickets'
import styled from 'styled-components'
import { useRequest } from '../../hooks'
import { ContactSupportAboutTicket } from './contact-support'
import { Dispute } from './Dispute'
import type { Shared_Ticket_DetailsFragment as Ticket } from './__generated__/Details'

function getEscalatedMessage(ticket: Ticket, escalatedType: EscalatedType) {
  switch (escalatedType) {
    case EscalatedType.Default:
      return 'We apologize for the inconvenience the reseller has caused by not processing this RMA ticket within two business days. Please click the Get Support link below so we can expedite your ticket.'
    case EscalatedType.Extended:
    case EscalatedType.Automatic:
      return 'We apologize for the inconvenience the reseller has caused by not processing this RMA ticket within seven business days. Please click the Get Support link below so we can expedite your ticket.'
    case EscalatedType.CustomerSupportAccepted:
      return 'We see that this ticket has been in the Approved state for 21 business days. Click below to give us more details about this situation so we can address it with the reseller on your behalf, or reassign your ticket to us to expedite your service.'
    case EscalatedType.CustomerSupportProcessing:
      return `We see that this ticket has been in the ${
        TICKET_STATUS_LABELS[ticket.status]
      } state for more than 7 business days. Click below to give us more details about this situation so we can address it with the reseller on your behalf, or reassign your ticket to us to expedite your service.`
    default:
      return 'Your ticket is escalated to Ubiquiti for support.'
  }
}

function customerSupportAvailable(escalatedType: EscalatedType) {
  return (
    escalatedType === EscalatedType.CustomerSupportAccepted || escalatedType === EscalatedType.CustomerSupportProcessing
  )
}

function getButtonText(escalatedType: EscalatedType) {
  if (customerSupportAvailable(escalatedType)) {
    return 'Contact Us'
  }

  return 'Get support'
}

export const GetSupportNotification = ({ ticket, refetchTicket }: { ticket: Ticket; refetchTicket?: any }) => {
  const [requestUiSupport, { loading: requestUiSupportLoading }] = useRequest('requestUiSupport')

  const [loading, setIsLoading] = useState(requestUiSupportLoading)
  const [showCustomerSupportModal, setShowCustomerSupportModal] = useState(false)
  const [alreadySubmitted, setAlreadySubmitted] = useState(false)

  const isSupportOffer = ticket.offerUiSupport !== EscalatedType.None && !ticket.escalatedToUi && !alreadySubmitted
  if (!isSupportOffer) {
    return null
  }

  const handleOnCustomerSupportComplete = async () => {
    setShowCustomerSupportModal(false)
    setAlreadySubmitted(true)

    if (refetchTicket) {
      await refetchTicket()
    }
  }

  const handleClick = async () => {
    if (customerSupportAvailable(ticket.offerUiSupport)) {
      setShowCustomerSupportModal(true)
      return
    }

    setIsLoading(true)

    try {
      await requestUiSupport({
        ticketId: ticket.id,
      })

      if (refetchTicket) {
        await refetchTicket()
      }
    } catch (e) {
      setIsLoading(false)
      // Show notification or something that would let user know that the operation failed
    }
  }

  return (
    <Dispute
      title={<>{ticket.supportByReseller ? 'Reseller' : 'Distributor'} Taking Too Long?</>}
      message={
        <>
          <StyledTextParagraph noBottomMargin={!!(!isSupportOffer && ticket.escalatedToUi)}>
            {!isSupportOffer && <Text>{getEscalatedMessage(ticket, EscalatedType.None)}</Text>}

            {isSupportOffer && <Text>{getEscalatedMessage(ticket, ticket.offerUiSupport)}</Text>}
          </StyledTextParagraph>

          {ticket.offerUiSupport && !ticket.escalatedToUi && (
            <div>
              <InlineButton
                loader={loading ? 'dots' : undefined}
                full={false}
                variant="inline"
                type="button"
                component={StyledLinkText}
                onClick={handleClick}
              >
                {getButtonText(ticket.offerUiSupport)}
              </InlineButton>
            </div>
          )}

          {showCustomerSupportModal && (
            <ContactSupportAboutTicket
              ticketId={ticket.id}
              supportedByName={ticket.supportByName}
              onDone={handleOnCustomerSupportComplete}
              onClose={() => setShowCustomerSupportModal(false)}
            />
          )}
        </>
      }
    />
  )
}

const InlineButton = styled(Button)`
  -webkit-appearance: none;
`

const StyledTextParagraph = styled.p<{ noBottomMargin?: boolean }>`
  margin: 0 0 12px 0;
  ${({ noBottomMargin }) => noBottomMargin && 'margin-bottom: 0;'}
`

const StyledLinkText = styled(Text)`
  color: ${({ theme }) => theme.colors.link};
`
