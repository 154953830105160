import { Button, Buttons, FileAttachments, Spacer } from '@shared-ui/components'
import useNavigate from '@shared-ui/hooks/useNavigate'
import type { DeviceId } from '@shared/types/brands'
import { CompanyType } from '@shared/types/tickets'
import { InfoIconL } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components'
import type { CountryCode } from 'libphonenumber-js'
import { useEffect } from 'react'
import { isSkuForwardedToUI } from 'rma-shared/lib/devices/devices'
import { theme } from 'styles/theme'
import { useSubmitRma } from '../../context/SubmitRma'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { RmaDevicePanelSmall } from '../rma-device-panel-small'
import { SellerInfo } from '../seller-info'
import { StepHeading } from '../step-heading'
import { SubmitContainer } from '../submit-container'
import { UCareGradient } from '../u-care'
import { UCareBadge } from '../u-care-badge'
import { useConfirmInformation_CreateTicketMutation as useCreateTicketMutation } from './__generated__/ConfirmInformation'

export function ConfirmInformation() {
  const { device } = useSubmitRmaStore()

  const isUICare = !!device?.isUnifiCare

  return (
    <SubmitContainer>
      {isUICare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column p-16 mt-6 gap-24 relative">
        <StepHeading heading="Let's review" />

        <Content />

        <Footer />
      </div>
    </SubmitContainer>
  )
}

function Content() {
  const {
    device,
    seller,
    currSku,
    shippingAddress,
    isOriginalSeller,
    shippingAddressValidated,
    manualUICare,
  } = useSubmitRmaStore()
  const {
    failureCategory,
    problemDescription,
    otherAttachmentFiles,
    proofOfPurchaseFiles,
    devicePhotos,
  } = useSubmitRma()

  const shipToAddress = shippingAddressValidated || shippingAddress
  const isUICare = !!device?.isUnifiCare
  const isUICareDistributor = isUICare && !device?.isSoldByUbiquiti
  const isForwardedToUI =
    device?.isForwardedToUI ||
    !!isSkuForwardedToUI(device?.coreItemCode || '', shipToAddress ? (shipToAddress.country as CountryCode) : null)

  const isUbiquiti = device?.isSoldByUbiquiti || false
  const showSellerNotification = !!seller
  const showSellerNotificationUI =
    showSellerNotification &&
    (isUbiquiti ||
      isUICare ||
      isUICareDistributor ||
      isForwardedToUI ||
      (!isOriginalSeller &&
        seller.entityType !== CompanyType.distributor &&
        device?.seller?.entityType !== CompanyType.distributor) ||
      seller.name === 'Ubiquiti')

  return (
    <>
      <div className="flex column">
        <Text size="body" color="secondary" weight="bold" className="mb-8">
          Product & Issue
        </Text>
        <RmaDevicePanelSmall device={device!} skuOverride={currSku} />
      </div>

      <div className="flex column">
        <Text size="body" color="secondary" className="mb-4">
          {failureCategory?.name || failureCategory?.id || 'Missing failure category.'}
        </Text>
        <Text size="body" color="tertiary">
          {problemDescription}
        </Text>
        <FileAttachments files={otherAttachmentFiles} />
      </div>

      {!!proofOfPurchaseFiles.length && (
        <div className="flex column">
          <Text size="body" color="primary" weight="bold">
            {isUICare && isUICareDistributor && !manualUICare ? 'Device Photo' : 'Proof of Purchase'}
          </Text>
          <FileAttachments files={proofOfPurchaseFiles} />
        </div>
      )}

      {!!devicePhotos.length && (
        <div className="flex column">
          <Text size="body" color="primary" weight="bold">
            Device Photo
          </Text>
          <FileAttachments files={devicePhotos} />
        </div>
      )}

      {seller && (
        <div className="flex column gap-8">
          <Text size="body" color="primary" weight="bold">
            Vendor
          </Text>

          <SellerInfo seller={seller} />

          {showSellerNotification && (
            <HandlerNotification
              handlerName={isOriginalSeller ? seller.name : device?.seller?.name}
              isByUbiquiti={showSellerNotificationUI}
            />
          )}
        </div>
      )}

      {shipToAddress && (
        <div className="flex column">
          <Text size="body" color="primary" weight="bold" className="mb-8">
            Shipping to
          </Text>

          <SellerInfo seller={shipToAddress} />
        </div>
      )}
    </>
  )
}

function Footer() {
  const { device, isProofOfPurchaseNeeded, shippingAddressId, seller, shippingAddress } = useSubmitRmaStore()
  const {
    userCompanyId,
    companyId,
    failureCategory,
    problemDescription,
    proofOfPurchaseFiles,
    otherAttachmentFiles,
    devicePhotos,
  } = useSubmitRma()

  const [createTicket, { loading, error, data }] = useCreateTicketMutation()
  const ticketId = data?.createTicket.ticket?.id
  const navigate = useNavigate()

  useEffect(() => {
    if (!ticketId) return

    SubmitRmaService.reset()

    navigate('/dashboard/tickets', { sidebarParam: ticketId })
  }, [ticketId, navigate])

  const submitRma = async () => {
    if (!seller) {
      return
    }

    const isNewSeller = seller.id === ''

    try {
      await createTicket({
        variables: {
          input: {
            deviceId: device?.id as DeviceId,
            reseller: isNewSeller
              ? { name: seller.name, suggestedAddress: seller.address1, website: seller.website }
              : null,
            companyId: isNewSeller ? null : seller.id,
            failureCategoryId: failureCategory?.id || '',
            description: problemDescription || '',
            proofOfPurchase: proofOfPurchaseFiles?.map(({ file }) => file),
            otherFiles: otherAttachmentFiles?.map(({ file }) => file),
            devicePhotos: devicePhotos.map(({ file }) => file),
            shippingAddress:
              shippingAddressId === -1
                ? {
                    address1: shippingAddress?.address1 ?? '',
                    city: shippingAddress?.city ?? '',
                    companyName: shippingAddress?.companyName ?? '',
                    country: shippingAddress?.country ?? '',
                    firstName: shippingAddress?.firstName ?? '',
                    lastName: shippingAddress?.lastName ?? '',
                    phoneNumber: shippingAddress?.phoneNumber ?? '',
                    state: shippingAddress?.state ?? '',
                    zipcode: shippingAddress?.zipcode ?? '',
                  }
                : null,
            shippingAddressId,
          },
        },
      })
    } catch {
      //
    }
  }

  const handleNext = () => {
    void submitRma()
  }

  const errors: string[] = []
  if (!device?.id) errors.push('Missing device.')
  if (!seller && !userCompanyId && !companyId) errors.push('Missing company.')
  if (!failureCategory?.id) errors.push('Missing failure category.')
  if (!problemDescription) errors.push('Missing problem description.')
  if (isProofOfPurchaseNeeded && !proofOfPurchaseFiles?.length) errors.push('Missing proof of purchase.')
  if (!shippingAddress && !shippingAddressId) errors.push('Missing address.')
  if (shippingAddress && (!shippingAddress.address1 || !shippingAddress.zipcode)) {
    errors.push('Your address is incomplete, please update it.')
  }

  return (
    <>
      {[...errors, error?.message].filter(Boolean).map((err) => (
        <>
          <Text color="danger">{err}</Text>
          <Spacer />
        </>
      ))}

      <Buttons>
        <Button
          variant="tertiary"
          disabled={loading || !!ticketId}
          onClick={SubmitRmaService.back}
          data-testid="submit-rma-back"
          $minWidth="0px"
        >
          Back
        </Button>

        <Button
          variant="primary"
          disabled={!!errors.length || loading}
          loader={loading ? 'dots' : undefined}
          onClick={handleNext}
          data-testid="submit-rma-next"
          $minWidth="0px"
        >
          Submit Request
        </Button>
      </Buttons>
    </>
  )
}

function HandlerNotification({ handlerName, isByUbiquiti }: { handlerName?: string; isByUbiquiti: boolean }) {
  const message =
    isByUbiquiti || !handlerName
      ? 'This RMA ticket will be handled by Ubiquiti.'
      : `Because you did not directly purchase this item from Ubiquiti, your RMA request will be handled by ${handlerName}.`

  return (
    <div className="flex p-8 bg-gray border-radius-4">
      <InfoIconL width={20} height={20} variant="fill" fill="blue" color={theme.colors.primary} />
      <Text size="body" className="ml-8 break-word">
        {message}
      </Text>
    </div>
  )
}
