import NotificationsSidebar from '@shared-ui/components/notifications/NotificationsSidebar'
import { DetailsTab, LogTab, MessagingTab, Ticket as TicketShared } from '@shared-ui/components/Ticket'
import type { TicketId } from '@shared/types/brands'
import { StarIcon } from '@ubnt/icons'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Rate } from './Ticket/Rate'
import { useTicketQuery } from './__generated__/Ticket'

export const RateTab = {
  id: 'rate',
  path: '/rate',
  icon: <StarIcon size="navigation" />,
  component: Rate,
}

export interface UrlParam {
  sidebarParam: string
}

interface SidebarProps {
  onClose: () => void
}

const RmaDetailsTab = {
  ...DetailsTab,
  props: {
    canCancelTicket: true,
  },
}

export default function Sidebar({ onClose }: SidebarProps) {
  const { sidebarParam } = useParams<UrlParam>()

  if (sidebarParam === 'notifications') {
    return <NotificationsSidebar onClose={onClose} />
  }

  return <TicketSidebar ticketId={sidebarParam} onClose={onClose} />
}

interface TicketProps {
  ticketId: TicketId
  onClose: () => void
}

const TicketSidebar = ({ ticketId, onClose }: TicketProps) => {
  const { data, loading, error, refetch } = useTicketQuery({
    variables: { id: ticketId },
  })

  const tabs = useMemo(
    () =>
      [RmaDetailsTab, LogTab, data?.ticket?.supportByUbiquiti === false ? MessagingTab : null, RateTab].filter(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
        <T extends unknown>(tab: T): tab is NonNullable<T> => !!tab,
      ),
    [data],
  )

  return (
    <TicketShared
      tabs={tabs}
      loading={loading}
      error={error}
      ticket={data?.ticket ?? undefined}
      refetchTicket={refetch}
      onClose={onClose}
    />
  )
}
